<template>
  <div>
    <el-dialog
      title="提现审核"
      :visible.sync="showDialog"
      width="400px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onCloseDialog"
    >
      <span class="content-box">
        <el-form label-width="80px" size="mini">
          <el-form-item label="审核状态">
            <el-radio-group v-model="submitData.passable" @change="">
              <el-radio
                v-for="item in [
                  { Name: '审核通过', Value: 1 },
                  { Name: '驳回', Value: 0 },
                ]"
                :key="item.Value"
                :label="item.Value"
              >
                {{ item.Name }}
              </el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-show="submitData.passable === 0" label="驳回原因">
            <el-input type="textarea" v-model="submitData.reject_reason" :rows="3" placeholder="不通过理由"></el-input>
          </el-form-item>
        </el-form>
      </span>
      <span slot="footer">
        <el-button @click="onCloseDialog">取 消</el-button>
        <el-button type="primary" @click="auditWithdraw">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Dealer from "@/api/dealer.js"

export default {
  data() {
    return {
      submitData: {
        withdraw_id: "",
        passable: 0,
        reject_reason: ''
      },
      showDialog: false,
    };
  },

  mounted() {},

  methods: {
    // 审核提现申请
    async auditWithdraw(){
        try {
            let { errcode } = await Dealer.auditWithdraw(this.submitData)
            if(errcode === 0){
                this.$message.success(`${this.submitData.passable === 1 ? '审核成功' : '驳回成功'}`)
                this.onCloseDialog()
                this.$emit('success')
            }
        } catch (err) {
            console.log(err);
        }
    },

    // 打开对话框
    onShowDialog(event) {
      this.submitData.withdraw_id = event.WithdrawId || "";
      this.showDialog = true;
    },

    // 关闭对话框
    onCloseDialog() {
      this.showDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
.content-box {
}
</style>
