<template>
  <div class="withdraw">
    <div class="df-row">
      <div class="df-col">
        <el-date-picker
          v-model="multipleDate"
          size="mini"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          @change="onChangeDate"
        >
        </el-date-picker>
      </div>

      <div class="df-col">
        <el-select
          v-model="submitData.status"
          placeholder="审核状态"
          size="mini"
        >
          <el-option
            v-for="(item, index) in [
              { Name: '全部', Value: -1 },
              { Name: '待审核', Value: 0 },
              { Name: '审核通过', Value: 1 },
              { Name: '驳回', Value: 2 },
              { Name: '已打款', Value: 3 },
            ]"
            :key="index"
            :label="item.Name"
            :value="item.Value"
          ></el-option>
        </el-select>
      </div>

      <!-- <div class="df-col">
        <el-select
          v-model="submitData.pay_type"
          placeholder="提现方式"
          size="mini"
        >
          <el-option
            v-for="(item, index) in [
              { Name: '全部', Value: 0 },
              { Name: '支付宝', Value: 1 },
              { Name: '银行卡', Value: 2 },
            ]"
            :key="index"
            :label="item.Name"
            :value="item.Value"
          ></el-option>
        </el-select>
      </div> -->

      <div class="df-col">
        <el-input
          v-model="submitData.word"
          placeholder="请输入昵称/姓名/手机号"
          size="mini"
          clearable
          @keyup.enter.native="onSearch"
        ></el-input>
      </div>

      <div class="df-col">
        <el-button type="primary" size="mini" @click="onSearch"
          >查 询</el-button
        >
      </div>
    </div>

    <el-table
      :data="tableData"
      :max-height="tableMaxHeight"
      size="mini"
      border
      stripe
      v-loading="loading"
    >
      <el-table-column prop="WithdrawNo" label="提现单号" width="120">
      </el-table-column>
      <el-table-column prop="" label="分销商" width="120">
        <template slot-scope="scope">
          <div class="dealer-info">
            <span>{{ scope.row.DealerId }}</span>
            <span>{{ scope.row.DealerMemberName }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="Amount" label="提现数额" width="90">
      </el-table-column>
      <el-table-column prop="CommissionTypeName" label="数额类型" width="90">
      </el-table-column>
      <el-table-column prop="PayTypeName" label="提现方式" width="90">
      </el-table-column>
      <el-table-column prop="" label="提现信息">
        <template slot-scope="scope">
          <div class="pay-info">
            <span>{{ scope.row.PayTransNo }}</span>
            <span>{{
              scope.row.PayTime
                ? util.dateFormat(
                    "YYYY-mm-dd HH:MM:SS",
                    new Date(scope.row.PayTime * 1000)
                  )
                : ""
            }}</span>
          </div>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="PayTransNo" label="提现交易号" show-overflow-tooltip> </el-table-column>
      <el-table-column prop="" label="提现完成时间" width="150">
        <template slot-scope="scope">
          {{
            scope.row.PayTime
              ? util.dateFormat(
                  "YYYY-mm-dd HH:MM:SS",
                  new Date(scope.row.PayTime * 1000)
                )
              : ""
          }}
        </template>
      </el-table-column> -->
      <el-table-column prop="StatusName" label="状态" width="90">
        <template slot-scope="scope">
          <el-tag
            :type="handlerStatus(scope.row.Status)"
            size="mini"
            effect="dark"
          >{{ scope.row.StatusName }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="" label="申请时间" width="150">
        <template slot-scope="scope">
          {{
            scope.row.CreateTime
              ? util.dateFormat(
                  "YYYY-mm-dd HH:MM:SS",
                  new Date(scope.row.CreateTime * 1000)
                )
              : ""
          }}
        </template>
      </el-table-column>
      <el-table-column prop="" label="审核时间" width="150">
        <template slot-scope="scope">
          {{
            scope.row.AuditTime
              ? util.dateFormat(
                  "YYYY-mm-dd HH:MM:SS",
                  new Date(scope.row.AuditTime * 1000)
                )
              : ""
          }}
        </template>
      </el-table-column>
      <el-table-column
        prop="Remark"
        label="备注"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column label="操作" width="220">
        <template slot-scope="scope">
          <el-button
            v-show="scope.row.Status < 3 && scope.row.Status != 2"
            type="primary"
            size="mini"
            @click="onShowAudit(scope.row)"
            >审核</el-button
          >
          <el-button
            v-show="scope.row.Status == 1"
            type="danger"
            size="mini"
            @click="execWithdrawVip(scope.row)"
            >确认打款</el-button
          >
          <!-- <el-button type="success" size="mini" @click="">微信付款</el-button> -->
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      background
      :page-sizes="[5, 10, 15, 20]"
      :page-size="submitData.page_size"
      :current-page="submitData.page_index"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalRecord"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
    </el-pagination>

    <AuditDialog
      ref="AuditDialog"
      @success="getDealerWithdrawList"
    ></AuditDialog>
  </div>
</template>

<script>
import Dealer from "@/api/dealer.js";

import AuditDialog from "./components/audit-dialog";

export default {
  components: { AuditDialog },

  data() {
    return {
      totalRecord: 0,
      tableMaxHeight: "",
      submitData: {
        word: "",
        begin_date: "",
        end_date: "",
        status: -1,
        // pay_type: 0,
        page_index: 1,
        page_size: 10,
      },
      multipleDate: [],
      tableData: [],
      loading: false,
    };
  },

  created() {
    this.getResize();
  },

  mounted() {
    window.addEventListener("resize", this.getResize);

    this.multipleDate = [
      this.util.getNowMonthToday(),
      this.util.getNowMonthToday(),
    ];
    this.onChangeDate();
    this.getDealerWithdrawList();
  },

  destroyed() {
    window.removeEventListener("resize", this.getResize);
  },

  computed:{
    handlerStatus(){
        return function(status){
            switch(status){
                case 0:
                    return 'info'; break;
                case 1:
                    return 'primary'; break;
                case 2:
                    return 'danger'; break;
                case 3:
                    return 'success'
            }
        }
    }
  },

  methods: {
    // 获现提现申请列表
    async getDealerWithdrawList() {
      this.loading = true;
      try {
        let { data } = await Dealer.getDealerWithdrawList(this.submitData);
        this.tableData = data.list;
        this.totalRecord = data.filter.TotalRecord;
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    // 确认打款
    execWithdrawVip(event) {
      this.$confirm("是否确认打款？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        try {
          let { errcode } = await Dealer.execWithdrawVip({
            withdraw_id: event.WithdrawId,
          });
          if (errcode === 0) {
            this.$message.success("打款成功");
            this.getDealerWithdrawList();
          }
        } catch (err) {
          console.log(err);
        }
      });
    },

    // 获取浏览器窗口大小
    getResize() {
      let { clientHeight } = this.util.getDocumentElementInfo();
      this.tableMaxHeight = clientHeight - (100 + 68 + 64);
    },

    onSearch() {
      this.submitData.page_index = 1;
      this.getDealerWithdrawList();
    },

    onShowAudit(event) {
      this.$refs.AuditDialog.onShowDialog(event);
    },

    // 日期选择器改变时间
    onChangeDate() {
      this.submitData.begin_date =
        this.multipleDate.length > 0
          ? this.multipleDate[0]
          : this.util.getNowMonthToday();
      this.submitData.end_date =
        this.multipleDate.length > 0
          ? this.multipleDate[1]
          : this.util.getNowMonthToday();
    },

    // 分页：每页显示多少条数据
    handleSizeChange(event) {
      this.submitData.page_index = 1;
      this.submitData.page_size = event;
      this.getDealerWithdrawList();
    },

    // 分页：跳转到第几页
    handleCurrentChange(event) {
      this.submitData.page_index = event;
      this.getDealerWithdrawList();
    },
  },
};
</script>

<style lang="less" scoped>
.withdraw {
  padding-top: 20px;

  .el-table {
    .dealer-info,
    .pay-info {
      .flex-col;
      align-items: unset;
    }
  }

  .el-pagination {
    margin-top: 20px;
    float: right;
  }
}
</style>
